import React, { useContext, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useDropzone } from "react-dropzone";
import { CgSpinnerAlt } from "react-icons/cg";
import { IoCheckmarkDoneSharp, IoCloseCircle } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { contractDetailsGAFunctions } from "./../GA/ContractDetails";
import { defautlChainId } from "./../constants/constantsValues";
import { AuthContext } from "./../context/AuthContext";
import ReactGA from "react-ga4";
import EncryptApiKey from "../helper/EncryptApiKey";
import { APIRoutes } from "../constants/ApiRoutes";
import { walletConnect } from "../helper/WalletConnect";
import { lighthouseClient } from "../helper/lighthouseClient";

export default function FrameCreate() {
  ReactGA.send("Frame Create Page");
  const formRef = useRef(null);
  const { address } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    image: "",
    title: "",
    description: "",
    createdBy: "",
    metadata: "",
    traits: [],
    animation_url: "",
  });
  const [isDisable, setDisable] = useState(false);
  const [mintState, setMintState] = useState(-1);
  const [traits, setTraits] = useState([1]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [thumb, setThumb] = useState("");
  const [files, setFiles] = useState([]);
  const [thumbnailfile, setThumbnailfile] = useState([]);
  const [showImg, setShowImg] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [count, setcount] = useState(1);
  const [token, setToken] = useState("");
  const [meta, setMeta] = useState("");
  const [assetClicked, setAssetClicked] = useState(true);
  const [mintType, setMintType] = useState(-1);
  const [airdropAddress, setAirDropAddress] = useState("");

  const [nftDetails, setNFtDetails] = useState({
    title: "",
    description: "",
    createdBy: "",
  });

  const location = useLocation();
  /** -------------------------------------------------------- */
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setThumb(acceptedFiles[0].type.slice(0, 5));
        setImage(acceptedFiles[0]);
        setShowImg(true);
      } else {
        toast.error("Please upload image only");
        return;
      }
    },
    // noClick: true,
  });

  const { getRootProps: thumbnailRoot, getInputProps: thumbnailInput } = useDropzone({
    accept: { "image/*": [".png", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setThumbnailfile(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setImage(acceptedFiles[0]);
        setShowThumbnail(true);
      } else {
        toast.error("Please upload image only for thumbnail");
        return;
      }
    },
    // noClick: true,
  });
  function countChange(e) {
    setcount(e.target.value);
  }
  function updateFormData(e) {
    setFormData((prv) => ({ ...prv, [e.target.id]: e.target.value }));
  }

  function addProperties() {
    const totalTraits = traits.length;
    setTraits((prev) => [...prev, totalTraits + 1]);
  }

  function removeProperty(item) {
    const newList = traits.filter((trait) => trait !== item);
    const totalTraits = newList.length;
    for (let i = item - 1; i < totalTraits; i++) {
      newList[i] -= 1;
    }
    setTraits(newList);
  }

  async function upload() {
    setLoading(true);
    setshowModal(false);
    const { title, description, createdBy, animation_url } = formData;
    if (!image) {
      setLoading(false);
      return toast.error("Please upload thumbnail!!");
    }
    if (!title || !description || !image || !createdBy) {
      setLoading(false);
      return toast.error("Please enter required parameters!!");
    }

    setMintState(0);

    const form = formRef.current;
    const traitType = form.trait_type;
    const traitValue = form.trait_value;
    const attributes = [];
    if (traits.length === 1) {
      if (traitType.value && traitValue.value) {
        attributes.push({
          trait_type: traitType.value,
          value: traitValue.value,
        });
      }
    } else {
      for (let i = 0; i < traits.length; i++) {
        if (traitType[i].value && traitValue[i].value) {
          attributes.push({
            trait_type: traitType[i].value,
            value: traitValue[i].value,
          });
        }
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      traits: attributes,
    }));
    try {
      const imageUploadResult = await lighthouseClient.uploadFile(image);
      const metadataUploadResult = await lighthouseClient.uploadMetadata(
        title,
        description,
        createdBy,
        attributes,
        animation_url,
        `ipfs://${imageUploadResult.fileHash}`
      );

      if (!imageUploadResult.success) {
        toast.error("Error in uploading image on IPFS");
        setLoading(false);
        setMintState(-1);
        return;
      }

      if (!metadataUploadResult.success) {
        toast.error("Error in uploading metadata on IPFS");
        setLoading(false);
        setMintState(-1);
        return;
      }

      const imageDb = imageUploadResult.fileHash;
      const metaUrl = metadataUploadResult.fileHash;
      console.log(`https://gateway.lighthouse.storage/ipfs/${metaUrl}`);
      setMeta(`https://gateway.lighthouse.storage/ipfs/${metaUrl}`);
      const formdata = new FormData();
      formdata.append("file", image);
      const encryptApiKey = await EncryptApiKey();
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}${APIRoutes.s3Upload}`, {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
          "x-api-key": encryptApiKey,
        },
        body: formdata,
      });
      const jsonData = await res.json();
      if (!res.ok || !res.status == 200) {
        toast.error(jsonData.message ? jsonData.message : "Error in uploading data in s3");
        setLoading(false);
        setMintState(-1);
        return;
      }
      setMintState(1);
      if (metaUrl) {
        toast.success("Data uploaded on IPFS");
        navigate("/frame-deploy", {
          state: {
            ipfsURL: `https://gateway.lighthouse.storage/ipfs/${imageDb}`,
            metaData: `ipfs://${metaUrl}`,
            s3url: jsonData?.file?.url,
          },
        });
      } else {
        toast.error("Error in uploading image on iPFS");
        setLoading(false);
        setMintState(-1);
      }

      return {
        status: true,
        result: {
          imageURL: imageUploadResult.fileHash,
          metadataURL: metadataUploadResult.fileHash,
        },
      };
    } catch (error) {
      setLoading(false);
      setMintState(-1);

      console.error(error.message);

      return {
        status: false,
        error: error.message,
      };
    }
  }

  //   const abi721Transfer = [
  //     "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
  //   ];
  //   const abi1155TransferSingle = [
  //     "event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)",
  //   ];

  //   async function MintNFT(imageD) {
  //     // todo provider window.ethereum
  //     const abi = await FetchABI(data.contractType);
  //     const nftContract = new ethers.Contract(
  //       contractAddress,
  //       abi,
  //       provider.getSigner()
  //     );
  //     let tx2;
  //     try {
  //       if (category == "oneOnone") {
  //         tx2 = await nftContract.mintNFT(
  //           airdropAddress ? airdropAddress : address,
  //           count,
  //           [`ipfs://${imageD}`]
  //         );
  //       } else if (category == "multiple") {
  //         // const counts = count.toString();
  //         tx2 = await nftContract.mintNFT(
  //           airdropAddress ? airdropAddress : address,
  //           count,
  //           `ipfs://${imageD}`,
  //           "0x"
  //         );
  //       }
  //       setMintState(2);
  //       setDisable(true);
  //       await tx2.wait();
  //       let tokenIds = [];
  //       if (category == "oneOnone") {
  //         const iface721TransferEvent = new ethers.utils.Interface(
  //           abi721Transfer
  //         );
  //         const receipt721 = await provider.getTransactionReceipt(tx2.hash);
  //         receipt721.logs.map((log) => {
  //           try {
  //             const parsedLog = iface721TransferEvent.parseLog(log);
  //             const { tokenId } = parsedLog.args;
  //             tokenIds.push(tokenId.toString());
  //           } catch (err) {
  //             //ignore if its not a Transfer event
  //           }
  //         });
  //       } else if (category == "multiple") {
  //         const iface1155TransferSingleEvent = new ethers.utils.Interface(
  //           abi1155TransferSingle
  //         );
  //         const receipt1155 = await provider.getTransactionReceipt(tx2.hash);
  //         receipt1155.logs.map((log) => {
  //           try {
  //             const parsedLog = iface1155TransferSingleEvent.parseLog(log);
  //             const { id } = parsedLog.args;
  //             tokenIds.push(id.toString());
  //           } catch (err) {
  //             //ignore if its not a TransferSingle event
  //           }
  //         });
  //       }
  //       setToken(tokenIds[0]);
  //       setFiles([]);
  //       setThumbnailfile([]);
  //       setTraits(0);
  //       setImage(null);
  //       setMintState(3);
  //       setShowImg(false);
  //       setAssetClicked(true);
  //       setShowThumbnail(false);
  //       setFormData({
  //         title: "",
  //         description: "",
  //         createdBy: "",
  //       });
  //       setcount(1);
  //       setNFtDetails({
  //         title: formData.title,
  //         description: formData.description,
  //         createdBy: formData.createdBy,
  //       });
  //       toast.success("Successfully Minted!!");
  //     } catch (err) {
  //       console.log(err);
  //       const errorMessage = MetaMaskError(err.message);
  //       toast.error(errorMessage);
  //       setLoading(false);
  //     } finally {
  //       setAirDropAddress("");
  //     }
  //   }

  return (
    <>
      {mintState === 3 ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100vh",
          }}
        >
          <Confetti
            friction={1}
            numberOfPieces={600}
            recycle={false}
            width={window.innerWidth || 300}
            height={window.innerHeight || 200}
          />
        </div>
      ) : null}
      <div style={{ paddingInline: "0" }} className="new-contract mintMain">
        <>
          <main className="main-content">
            <article className="content mintTop">
              <h1 className="content-heading">Write your contract details carefully</h1>
              <p className="content-description">
                Your contract name is a key identifier that appears wherever your contract is mentioned or listed.
                Typically, it reflects your artist name, collection name, brand, or project name.Your contract name is a
                key identifier that appears wherever your contract is mentioned or listed. Typically, it reflects your
                artist name, collection name, brand, or project name.
              </p>
              <>
                {mintState === 3 ? (
                  <></>
                ) : address ? (
                  <button className="deploy-btn mintOne" disabled={isLoading} form="mintForm">
                    Upload on IPFS
                  </button>
                ) : (
                  <button className="deploy-btn mintOne" onClick={() => walletConnect(defautlChainId)}>
                    Connect Wallet
                  </button>
                )}
              </>

              <>
                {isLoading ? (
                  <div className="deployment-processing-states mTop">
                    <div className="states-description">
                      <>
                        <p className="state-text">Uploading on IPFS</p>
                        {mintState >= 0 ? (
                          <span className="state-symbol">
                            {mintState === 0 ? (
                              <CgSpinnerAlt className="spinner" />
                            ) : (
                              <IoCheckmarkDoneSharp className="check-mark" />
                            )}
                          </span>
                        ) : null}
                      </>
                    </div>
                  </div>
                ) : null}
              </>
              {!address ? (
                <small
                  style={{
                    marginTop: "-1.5rem",
                    fontWeight: "300",
                  }}
                  className="wallet-connect-msg"
                >
                  Please connect your wallet first!
                </small>
              ) : null}
            </article>
            <form
              className="contract-information mintForm"
              id="mintForm"
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                contractDetailsGAFunctions.createFrameEvent();
                if (!image) {
                  return toast.error("Please upload thumbnail!!");
                }

                upload();
              }}
            >
              {assetClicked ? (
                <div className="information-group nftContent">
                  {!showImg ? (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div className="image-cont">
                        <p className="add">+</p>
                        <p className="add_upload">Upload Image</p>
                      </div>
                    </div>
                  ) : null}

                  {files.map((path) => {
                    return (
                      <div className="information-group-img ">
                        {path.type.slice(0, 5) == "image" ? (
                          <img key={path} src={path.preview} alt="" />
                        ) : (
                          <iframe src={path.preview} height="200" width="300" title="Iframe Example"></iframe>
                        )}

                        {!isDisable ? (
                          <IoCloseCircle
                            className="remove-img-btn"
                            onClick={() => {
                              setFiles([]);
                              setImage(null);
                              setShowImg(false);
                            }}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="information-group nftContent">
                  {!showThumbnail ? (
                    <div {...thumbnailRoot({ className: "dropzone" })}>
                      <input {...thumbnailInput()} />
                      <div className="image-cont">
                        <p className="add">+</p>
                        <p className="add_upload">Upload Thumbnail</p>
                      </div>
                    </div>
                  ) : null}

                  {thumbnailfile.map((path) => {
                    return (
                      <div className="information-group-img ">
                        <img key={path} src={path.preview} alt="" />
                        {!isDisable ? (
                          <IoCloseCircle
                            className="remove-img-btn"
                            onClick={() => {
                              setThumbnailfile([]);
                              setImage(null);
                              setShowThumbnail(false);
                            }}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              )}
              {thumb == "video" || thumb == "audio" ? (
                <div className="information-group video-box">
                  <p onClick={() => setAssetClicked(true)}>Asset</p>
                  <p onClick={() => setAssetClicked(false)}>Thumbnail</p>
                </div>
              ) : null}

              <div className="information-group">
                <label className="label">Title</label>
                <input
                  className="name"
                  type="text"
                  placeholder="Ex. - Rare Pepe"
                  value={formData.title}
                  name="title"
                  id="title"
                  required={true}
                  readOnly={isDisable}
                  onChange={(e) => updateFormData(e)}
                />
              </div>

              <div className="information-group desc">
                <label className="label">Description</label>
                <textarea
                  className="name"
                  type="text"
                  placeholder="Ex. - Rare Pepe by Matt Furie"
                  value={formData.description}
                  name="description"
                  id="description"
                  required={true}
                  readOnly={isDisable}
                  onChange={(e) => updateFormData(e)}
                />
              </div>
              <div className="information-group">
                <label className="label">Properties</label>
                {traits.length
                  ? traits.map((item) => {
                      return (
                        <div className="properties" key={item}>
                          {" "}
                          <button type="button" onClick={() => removeProperty(item)}>
                            <RxCross2 />
                          </button>
                          <input
                            type="text"
                            className="properties-form-control"
                            name="trait_type"
                            placeholder="Property Title*"
                            readOnly={isDisable}
                          />
                          <input
                            type="text"
                            className="properties-form-control"
                            name="trait_value"
                            placeholder="Property Value*"
                            readOnly={isDisable}
                          />{" "}
                        </div>
                      );
                    })
                  : null}
                <button type="button" className="add-properties" onClick={() => addProperties()} disabled={isDisable}>
                  Add More
                </button>
              </div>
              <div className="information-group">
                <label className="label">Created By</label>
                <input
                  className="name"
                  type="text"
                  value={formData.createdBy}
                  placeholder="Satoshi Nakamoto"
                  name="createdBy"
                  id="createdBy"
                  required={true}
                  readOnly={isDisable}
                  onChange={(e) => updateFormData(e)}
                />
              </div>
            </form>
          </main>
        </>
      </div>
    </>
  );
}
