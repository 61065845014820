import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { contractDetailsGAFunctions } from "../GA/ContractDetails";
import FetchABI from "../Hooks/GetABI";
import { appImages } from "../constants/images";
import Loader from "./Loader";
import constantsValues from "../constants/constantsValues";
import GetAlchemySetting from "../helper/GetAlchemySetting";
import { Alchemy } from "alchemy-sdk";

function Tokens({ data }) {
  // states
  const [listOfNft, setlistedNFTs] = useState([]);
  const [loading, setLoading] = useState(true);
  // functions
  const navigate = useNavigate();
  async function getMintedNFT() {
    const alchemySetting = await GetAlchemySetting(data?.chainId);
    let getABI;
    getABI = await FetchABI(data.contractType);
    // const userProvider = new ethers.providers.Web3Provider(window.ethereum);
    const userProvider = ethers.getDefaultProvider(constantsValues[data.chainId].rpcURL);
    const nftContract = new ethers.Contract(data.contractAddress, getABI, userProvider);
    if (alchemySetting) {
      //Fetch All NFTs With Alchemy SDK
      const alchemy = new Alchemy(alchemySetting);
      const response = await alchemy.nft.getNftsForContract(data?.contractAddress, {});
      if (response?.nfts.length > 0) {
        const responseArray = response?.nfts;

        for (let index = 0; index < responseArray.length; index++) {
          let currentsupply;

          if (data?.category === "multiple") {
            currentsupply = parseInt(await nftContract.totalSupply(index + 1));
          }

          let dict = {
            tokenid: responseArray[index]?.tokenId,
            name: responseArray[index]?.title,
            imgUrl: responseArray[index]?.media[0]?.gateway,
            createdBy: responseArray[index]?.rawMetadata?.createdBy,
            edition: currentsupply,
            description: responseArray[index]?.description,
          };
          setlistedNFTs((prv) => [...prv, dict]);
          setLoading(false);
        }
        return;
      }
    } else {
      // Fetch All NFTs For That Chain Which Is not Supported By Alchemy
      let currentsupply;

      if (data.category === "multiple") {
        for (let index = 1; index <= 12; index++) {
          currentsupply = parseInt(await nftContract.totalSupply(index));
          if (currentsupply > 0) {
            let allNFTs = [];
            for (let j = 1; j <= 1; j++) {
              let tokenuri = await nftContract.uri(index);
              let correctTokenURI = `https://gateway.lighthouse.storage/ipfs/${tokenuri.split("ipfs://")[1]}`;
              const response = await fetch(correctTokenURI);
              const responseData = await response.json();
              const nftName = responseData.name;
              const createdBy = responseData.createdBy;
              const description = responseData.description;
              const imgurl = `https://gateway.lighthouse.storage/ipfs/${responseData.image.split("ipfs://")[1]}`;
              const edition = currentsupply;
              let dict = {
                tokenid: index,
                name: nftName,
                imgUrl: imgurl,
                createdBy: createdBy,
                edition: edition,
                description: description,
              };
              setlistedNFTs((prv) => [...prv, dict]);
            }
          } else {
            setLoading(false);
            break;
          }
        }
      } else {
        currentsupply = parseInt(await nftContract.totalSupply());
        let allNFTs = [];

        for (let i = 1; i <= currentsupply; i++) {
          let tokenuri = await nftContract.tokenURI(i);
          console.log({ tokenuri });
          let correctTokenURI = `https://gateway.lighthouse.storage/ipfs/${tokenuri.split("ipfs://")[1]}`;
          const response = await fetch(correctTokenURI);
          const responseData = await response.json();
          const nftName = responseData.name;
          const imgdata = responseData.image;
          const createdBy = responseData.createdBy;
          const description = responseData.description;
          const imgurl = `https://gateway.lighthouse.storage/ipfs/${responseData.image.split("ipfs://")[1]}`;
          let dict = {
            tokenid: i,
            name: nftName,
            imgUrl: imgurl,
            createdBy: createdBy,
            description: description,
          };
          setlistedNFTs((prv) => [...prv, dict]);
        }
      }
    }
    setLoading(false);
  }

  //   useEffect
  useEffect(() => {
    getMintedNFT();
  }, []);
  return (
    <>
      <div className="tokenPage marginT">
        {!loading ? (
          <>
            {listOfNft.length > 0 ? (
              <main className="main-container">
                {listOfNft.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="containers"
                      onClick={() => {
                        contractDetailsGAFunctions.viewNFTEvent();
                        navigate(`/nft/${data.contractAddress}`, {
                          state: {
                            imageURL: item.imgUrl,
                            name: item.name,
                            createdBy: item.createdBy,
                            description: item.description,
                            contractData: data,
                            token: item?.tokenid,
                          },
                        });
                      }}
                    >
                      {item?.edition ? <div className="edition">x{item?.edition}</div> : null}

                      <span className="container-image">
                        <img src={item.imgUrl} />
                      </span>
                      <div className="containers-details">
                        <div className="containers-details-top">
                          <p className="containers-details-name">{item.name}</p>
                          <p>#{item.tokenid}</p>
                        </div>
                        <div className="containers-details-lower">
                          <div className="lower">
                            <img src={appImages.collection} />
                            <div>
                              <p>Created By</p>
                              <p>{item.createdBy}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </main>
            ) : (
              <div className="nodata-container">
                <p className="msg-heading">It seems you haven&apos;t minted any NFT yet!</p>
                <button
                  className="deploy-contract-btn"
                  onClick={() => {
                    contractDetailsGAFunctions.createNFTEvent();
                    navigate("/mint-tokens", {
                      state: {
                        contractAddress: data.contractAddress,
                        category: data.category,
                        data: data,
                      },
                    });
                  }}
                >
                  Create Now
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="loader-fetch">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
}

export default Tokens;
